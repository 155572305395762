/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
/* * {
  font-family: 'Raleway', sans-serif;
} */
body{
  font-family: 'Raleway', sans-serif !important;
}
.kds-main-text-box {
  position: absolute;
  margin: auto;
  display: block;
  text-align: center;
  bottom: 10%;
  z-index: 111;
  left: auto;
  width: 100%;
}
p.kds-main-text{
  /* color: #ee3330; */
  color: #ffffff;
    font-size: 13px!important;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 20px;
    padding-top: 15px;
    text-transform: uppercase;
  line-height: 20px;
}
ul.navigation-home-top-page {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}
.social-icons p a {
  text-decoration: none;
}
ul.navigation-home-top-page li {
  display: inline-block;
}
.card{
  border-radius: 0rem !important;
}

ul.navigation-home-top-page li a {
  display: block;
  padding: 30px 0px;
  width: 160px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  color: #777;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}
ul.navigation-home-top-page li a.active::before {
  content: "";
  background-color: #ee3330;
  height: 50px;
  width: 1px;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -0.5px;
}
ul.navigation-home-top-page li a.active::after {
  content: "";
  background-color: #ee3330;
  height: 50px;
  width: 1px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -0.5px;
}
ul.navigation-home-top-page li a.active {
  color: #000;
}
ul.navigation-home-top-page li a.active{
  position: relative;
}
ul.navigation-home-top-page li a.active::after {
  content: "";
  background-color: #ee3330;
  height: 50px;
  width: 1px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -0.5px;
}

ul.navigation-home-top-page li a.active::before {
  content: "";
  background-color: #ee3330;
  height: 50px;
  width: 1px;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -0.5px;
}

section.about-section {
  padding: 24px 0px;
}

.col.text-right.logo-right img {
  margin-top: -40px;
}
.side-icon img{
  width: 320px;
}
ul.inner_sub {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
  margin-top: 58px;
}

ul.inner_sub li {
  display: inline-block;
}

ul.inner_sub li a {
  display: block;
  padding: 10px 0px;
  width: 75px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: #777;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}
ul.inner_sub li:last-child a{
  width: 100px;
}

.card-columns .card {
  margin-bottom: 20px !important;
}
.card{
  border: 0.5px solid rgba(0,0,0,.125);
  border-radius: 0rem;
}
.card-img-top{
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
ul.navigation-home-top li a.active{
  position: relative;
}

ul.inner_sub li a.active {
  color: #000;
}
ul.navigation-home-top li a.active::before {
  content: "";
  background-color: #ee3330;
  height: 100px;
  width: 1px;
  position: absolute;
  top: -110px;
  left: 50%;
  margin-left: -0.5px;
}
ul.navigation-home-top li a.active::after {
    content: "";
    background-color: #ee3330;
    height: 100px;
    width: 1px;
    position: absolute;
    bottom: -110px;
    left: 50%;
    margin-left: -0.5px;
}
hr.top-sep {
  margin-bottom: 40px;
  margin-top: 0;
}
h3 {
  text-transform: uppercase;
  font-size: 13px !important;
  letter-spacing: 2px;
}
h1.heading {
  /* font-weight: 100; */
  font-weight: 400;
  font-size: 28pt;
  color: #1c1c1c;
  text-transform: uppercase;
  letter-spacing: 0px;
}

p {
  font-size: 13px;
  color: #000000;
  font-weight: 400;
  /* line-height: 32px; */
  line-height: 26px;
  letter-spacing: 0.5px;
}

.red-arrow-t, .red-arrow-r, .red-arrow-b, .red-arrow-l, .red-arrow-t-c, .red-arrow-b-c {
  position: relative;
}

.red-arrow-t::after, .red-arrow-r::after, .red-arrow-b::after, .red-arrow-l::after, .red-arrow-t-c::before, .red-arrow-b-c::after {
  content: "";
  background-color: #ee3330;
  position: absolute;
}

.red-arrow-t::after {
  bottom: 0;
  right: 0;
  height: 140px;
  width: 1px;
}

.red-arrow-r::after {
  bottom: 0;
  left: 0;
  height: 1px;
  width: 140px;
}

.red-arrow-b::after {
  top: 0;
  left: -1px;
  height: 140px;
  width: 1px;
}

.red-arrow-l::after {
  top: -1px;
  right: 0;
  height: 1px;
  width: 140px;
}

.red-arrow-b-c::after {
  bottom: -150px;
  left: 50%;
  margin-left: -1px;
  height: 140px;
  width: 1px;
}

.red-arrow-t-c::before {
  top: -150px;
  left: 50%;
  margin-left: -1px;
  height: 140px;
  width: 1px;
}
.text-red{
  color: red !important;
}
ul.social {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

ul.social li {
  display: inline-block;
  margin-left: 12px;
}

ul.social li a {
  color: #acacac;
}

ul.social li a:hover {
  color: #000;
}

h3 {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

h3.designation {
  color: #919191;
  font-size: 11px !important;
}

ul.pro-detail {
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

ul.pro-detail li {
  text-transform: uppercase;
  font-size: 13px;
  color: #303030;
  margin-bottom: 10px;
  background-image: url('/images/plus.png');
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 15px;
}

.join-the-KDS {
  background-color: #f6f6f6;
  padding: 50px 0;
  margin-top: 50px;
  margin-bottom: 0px;
}

.join-the-KDS a {
  display: inline-block;
  background-color: #303030;
  text-transform: uppercase;
  color: #fff !important;
  text-decoration: none;
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.join-the-KDS a:hover {
  background-color: #000;
}

ul.awards_list {
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

ul.awards_list li {
  margin-bottom: 70px;
  background-image: url('/images/plus.png');
  background-position: left top 6px;
  background-repeat: no-repeat;
  padding-left: 15px;
}

ul.awards_list li:last-child {
  margin-bottom: 0;
}

ul.awards_list h3 {
  line-height: 19px;
}

ul.awards_list p {
  line-height: 21px;
  font-size: 13px;
  color: #919191;
  letter-spacing: 1px;
}

span.tag {
  display: inline-block;
  font-size: 13px;
  background-color: #eee;
  color: #1b1b1b;
  padding: 5px 15px;
  letter-spacing: 3px;
}
.min-h-300{
  min-height: 280px;
}
.mb-70 {
  margin-bottom: 70px;
}
.award-img {
  width: 60%;
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
  float: right;
}
.logo-right {
  position: absolute;
  right: 20px;
  margin-top: -30px;
}
.logo-right img{
  width: 240px;
  /* padding: 0px 10px; */
}
.logo-right-down {
  position: absolute;
  right: 20px;
  top: 0px;
  margin-top: 10%;
}

.logo-left {
  position: absolute;
  left: 20px;
  z-index: 999;
}

h3.red-arrow-b-c {
  margin-bottom: 200px;
}

h3.red-arrow-t-c {
  margin-top: 150px;
}

.project-info h1.heading {
  margin-bottom: 30px;
}

.swiper-container {
  width: 100%;
  margin-bottom: 40px;
}

.project-info ul.pro-detail {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.project-info ul.pro-detail li {
  margin-bottom: 30px;
}

.next-and-pre-pro {
  text-align: center;
  margin: 40px 0;
}

.next-and-pre-pro a {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-repeat: no-repeat;
  color: #777777;
  text-decoration: underline;
  position: relative;
}

a.pre {
  background-image: url('/images/pre.jpg');
  background-position: center right;
  padding-right: 18px;
}

.client-logo-section .logo-imgs{
text-align: center;
padding: 15px;
}
a.next {
  background-image: url('/images/next.jpg');
  background-position: center left;
  padding-left: 18px;
}

a.pre::after, a.next::after {
  content: "";
  background-color: #ee3330;
  width: 1px;
  height: 40px;
  position: absolute;
}

a.pre::after {
  right: -15px;
  top: -45px;
}
section.next-project-type a:active, section.next-project-type a:hover {
  color: #000 !important;
}
.swiper-pagination-bullet-active {
  background-color: #696969 !important;
  opacity: 1 !important;
  outline: none;
}

.swiper-pagination-bullet {
  background-color: rgb(255, 255, 255);
  opacity: 0.3;
}

a.next::after {
  left: -16px;
  bottom: -45px;
}

section.next-project-type {
  margin-top: 40px;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after, 
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
display: none;
content: 'transparent';
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url('/images/slider_next.jpg');
  right: -20px !important;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url('/images/slider_pre.jpg');
  left: -20px !important;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 10px;
  height: 19px;
  margin-top: -22px;
  z-index: 10;
  outline: none;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
}
.modal-body{
  padding: 0rem !important;
}
.mobile-menu, .mobile-show {
  display: none;
}
.modal-dialog{
  max-width: 50% !important;
}
.home-logo-awaed-set{
  display: grid;
}
.home-logo-awaed-set img{
  width: 100%;
  height: 100vh;
  padding-bottom: 10px;
}
.landscape-mode {
  display: none;
}

section.about-section.fixed {
  position: fixed;
  background-color: rgb(255, 255, 255);
  width: 100%;
  z-index: 9;
}

#home-slider {
  position: relative;
  height: 100vh;
}

#home-slider .swiper-slide {
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swal2-container {
  z-index: 9999999999999 !important;
}
.mobile-rotate-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 999999999;
}
.side-icon {
  position: absolute;
  /* right: 30px; */
  right: 10px;
}
p.rotate {
  text-align: center;
  top: 30%;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
}

i.fa.fa-mobile-phone {
  font-size: 40px!important;
}


.title-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 500;
}

.img-1 {
  background-image: url('/images/solution_img_1.jpg');
}

.img-2 {
  background-image: url('/images/solution_img_2.jpg');
}

.img-3 {
  background-image: url('/images/solution_img_3.jpg');
}

.img-4 {
  background-image: url('/images/solution_img_4.jpg');
}

.img-5 {
  background-image: url('/images/solution_img_5.jpg');
}

.img-6 {
  background-image: url('/images/solution_img_6.jpg');
}

.img-7 {
  background-image: url('/images/solution_img_7.jpg');
}

.img-8 {
  background-image: url('/images/solution_img_8.jpg');
}

.details {
  background-color: #ffffff;
  min-height: 360px;
  padding: 30px;
  border: 1px solid #efefef;
}

.details p {
  color: #2d2d2d;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
}

.details p:first-child:first-letter {
  display: block;
  float: left;
  font-size: 70px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  font-weight: 700;
}

.solution-content {
  padding-bottom: 3rem;
}

.solution-plus::after {
  content: "";
  position: absolute;
  background-image: url('/images/pro_arrow.png');
  background-repeat: no-repeat;
  width: 74px;
  height: 74px;
  right: -37px;
  bottom: -37px;
  background-position: center;
}

.pro_wrap {
  position: relative;
  margin-bottom: 30px;
}
section.home-top-section section.footer-nav, section.home-top-section .footer-bottom {
  DISPLAY: NONE;
}
.pro_wrap a.projectlink{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  background-image: url('/images/pro_arrow.png');
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}
.pro_wrap.pronew-2 a.projectlink {
  background-position: center 32%;
}
.col-md-4.pronew-1:hover .pronew-2 a {
  background-color: transparent;
}
h5.news-date {
  font-size: 11px!important;
  text-transform: uppercase;
  font-weight: 600;
}
.col-md-4.pronew-1 .pronew-2 a {
  opacity: 0;
}
.leadership-cross .red-arrow-l::after {
  top: 7px;
  right: 15px;
  height: 1px;
  width: 140px;
}
.leadership-cross .red-arrow-r::after {
  bottom: 7px;
  left: 15px;
  height: 1px;
  width: 140px;
}
.col-md-4.pronew-1:hover .pronew-2 a {
  opacity: 1;
}
.pronew-2:hover:before{
  content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.76);
    /* background-image: url(/images/pro_arrow.png); */
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
}
.pronew-2:hover:before a.projectlink, .col-md-4.pronew-1:hover{
  opacity: 1;
}
.pronew-2{
  position: relative;
}

.pronew-1::before{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  /* background-image: url(/images/pro_arrow.png);
  background-position: center 32%;
  background-repeat: no-repeat; */
  
}
.pro_wrap ul {
  position: absolute;
  bottom: 6%;
  opacity: 0;
  padding-right: 15px;
}
.pro_wrap ul a.projectinterlink{
  bottom: 0;
  width: 100%;
  background-color:transparent;
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  top: 18%;
}
.pro_wrap ul a{
  text-decoration: none;
  list-style-type: circle;
}
/* .pro_wrap.pronew-2{
  position: relative;
}
.pro_wrap.pronew-2:hover{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  background-image: url(/images/pro_arrow.png);
  background-position: center 32%;
  background-repeat: no-repeat;
} */
.pro_wrap ul li::marker{
display: none;
}
.pro_wrap ul li::before{
  content: "\2022";
  color: #fff;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
.pro_wrap:hover ul, .pro_wrap a.projectlink ul{
  opacity: 1;
}
.pro_wrap a.projectlink span{
  position: absolute;
  top: 35px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  color: #fff;
  font-weight: 600;
}
.pro_wrap ul li span {
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 600;
}
.social-icons h3{
  font-size: 12px !important;
  font-weight: 400;
  font-family: Raleway,sans-serif!important;
}

.pro_wrap a.projectlink:hover {
  opacity: 1;
}

.news-img {
  position: relative;
}

.news-img a {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  background-image: url('/images/pro_arrow.png');
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

.news-img a span {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  color: #fff;
  font-weight: 600;
}

.news-img a:hover {
  opacity: 1;
}

.news-details {
  border: 1px solid #e8e8e8;
  padding: 30px;
}

.news-wrap {
  margin-bottom: 30px;
}

.news-details p {
  margin-bottom: 0;
}

section.news-list {
  min-height: 990px;
}

a.navbar-brand img {
  height: 35px;
}

button.navbar-toggler {
  float: right;
  border: 0;
  margin-top: 4px;
  outline: none;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light.mobile-menu {
  background-color: #fff !important;
}

section.footer-nav {
  background-color: #ebebeb;
  padding: 30px 0;
}
.social-icons i.fa, i.fab.fa-youtube{
  font-size: 18px;
  color: #000;
  transition: all ease 0.5s;
}
.social-icons i.fa:hover, i.fab.fa-youtube:hover{
  color: #828282;
  transition: all ease 0.5s;
}
.social-icons span {
  padding-right: 18px;
}
.social-icons p{
  margin-bottom: 10px !important;
}
ul.navigation-footer {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

ul.navigation-footer li {
  display: inline-block;
}

ul.navigation-footer li a {
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  margin-right: 25px;
  color: #5e5e5e;
  letter-spacing: 2px;
  text-decoration: none;
}

ul.navigation-footer li a.active {
  color: black;
}

.footer-bottom p,
.footer-bottom p a {
  font-family: 'Open Sans', sans-serif;
  color: #5e5e5e;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 0;
  letter-spacing: 1px;
}
a.sc-7dvmpp-1.gCdMUP {
  display: none !important;
}
span.f-black {
  color: #000;
  font-family: Raleway,sans-serif!important;
  text-transform: uppercase;
}
.leadership-cross .red-arrow-l::after, .leadership-cross .red-arrow-r::after{
  background-color: #ee3330 !important;
}
.c-title a {
  font-size: 16px;
  color: #000000 !important;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1.5px;
  padding-top: 45px !important;
}
.join-the-KDS.text-center a {
  text-decoration: none;
}
.footer-bottom {
  padding: 40px 0;
}

.copyrights p,
.copyrights p a {
  font-size: 10px;
  color: #919191;
  letter-spacing: 1px;
  text-decoration: none;
}

.copyrights p a:hover {
  color: #f00;
}

.contact-details {
  background-color: #f9f9f9;
  height: 350px;
  padding: 50px;
}
.social-icons i.fa, i.fab.fa-youtube{
  font-size: 18px;
  color: #000;
  transition: all ease 0.5s;
}
.social-icons i.fa:hover, i.fab.fa-youtube:hover{
  color: #828282;
  transition: all ease 0.5s;
}
.social-icons span {
  padding-right: 18px;
}
.social-icons p{
  margin-bottom: 10px !important;
}
.contact-details p,
.contact-details p a {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #303030;
}

.contact-details p a {
  text-transform: lowercase;
}

.contact-details img {
  margin-right: 5px;
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea, textarea.form-control{
  border: 1px solid #c6c6c6 !important;
  border-radius: 0;
  margin-bottom: 15px;
  font-size: 12px;
  box-shadow: none !important;
}

input[type="submit"] {
  display: inline-block;
  background-color: #303030;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  padding: 8px 60px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: black;
}

section.contact-section {
  padding-bottom: 6rem;
}

input.form-control.is-invalid,textarea.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.c-title {
  background-color: #f6f6f6;
  padding: 40px;
  text-align: center;
}

.overlay {
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.67);
  z-index: 9999999999;
}

.overlay {
height: 100vh !important;
width: 100vw !important;
overflow: hidden !important;
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
textarea {
  border: 1px solid #c6c6c6 !important;
  border-radius: 0;
  margin-bottom: 15px;
  font-size: 12px;
  box-shadow: none !important;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

select {
  background-image: url('/images/drop_icon.png');
  background-position: center right 10px;
  background-repeat: no-repeat;
}

input[type="submit"] {
  display: inline-block;
  background-color: #303030;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  padding: 8px 60px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}

.form {
  background-color: #fff;
  padding: 40px 0;
}

a.close-win {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  color: #424242;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.about-details-awards {
  padding-bottom: 60px;
}

.home-top-section {
  background-image: url('/images/home_top_bg.jpg');
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}

.site-logo {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 10%;
}

ul.navigation-home-top {
  list-style: none;
  padding: 0;
  text-align: center;
}

ul.navigation-home-top li {
  display: inline-block;
}

ul.navigation-home-top li a {
  display: block;
  padding: 10px 0px;
  width: 160px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  color: #777;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}

ul.navigation-home-top li a.active {
  color: #fff;
}

ul.navigation-home-top li a.active::after {
  content: "";
  background-color: #ee3330;
  height: 100px;
  width: 1px;
  position: absolute;
  bottom: -110px;
  left: 50%;
  margin-left: -0.5px;
}

ul.navigation-home-top li a.active::before {
  content: "";
  background-color: #ee3330;
  height: 100px;
  width: 1px;
  position: absolute;
  top: -110px;
  left: 50%;
  margin-left: -0.5px;
}


/* .side-icon img:first-child{
  padding: 0px 10px;
} */
.side-icon {
  z-index: 9;
}
.side-icon-down{
  position: absolute;
  right: 30px;
  top: 35%;
  z-index: 9; 
}

.d-table.w-100.h-100 {
  position: absolute;
  z-index: 9;
}

.site-logo {
  z-index: 9;
}

ul.navigation-home-top li a {
  color: #fff !important;
  font-weight: 600 !important;
}

.slider-text-wrap {
  font-size: 14px;
  position: absolute;
  width: 100%;
  height: 60px;
  background-color: #0000007a;
  z-index: 9999999999999;
  right: 0;
  bottom: 0;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.slider-text-wrap{
  font-size:13px!important;
  letter-spacing:2px!important;
  text-decoration:none!important;
  font-weight:600!important;
  font-family:Raleway,sans-serif!important;
}

#home-slider {
  position: relative;
  height: 100vh;
}

section.home-top-section {
  position: relative;
}

section.about-details {
  padding-bottom: 60px;
}
button#subscriber_btn:hover{
  background-color: black;
}
button#subscriber_btn{
  display: inline-block;
  background-color: #303030;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  padding: 8px 60px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  border-radius: 0px;
  outline: none;
  box-shadow: none;
}
i.fa.fa-mobile {
  font-size: 55px;
}
.hide{
display: none !important;
}
.show{
display: inline-block !important;
}
.preloader{
  background-image:url(/images/preloader.png);
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.success{
  color: #33d962;
}