i.fas.fa-paperclip.fa-2x.new_214587 {
    margin-left: 20px;
}
.form-control {

    border: 1px solid #c6c6c6 !important;
    border-radius: 0;
    margin-bottom: 15px;
    font-size: 15px;
    box-shadow: none !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
select#Civil\ suppliers {
    border: 1px solid #c6c6c6 !important;
    border-radius: 0;
    margin-bottom: 15px;
    font-size: 15px;
    box-shadow: none !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
select#Civil\ works {
    border: 1px solid #c6c6c6 !important;
    border-radius: 0;
    margin-bottom: 15px;
    font-size: 15px;
    box-shadow: none !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
/* input.form-control {
    border: 1px solid #c6c6c6 !important;
    border-radius: 0;
    margin-bottom: 15px;
    font-size: 15px;
    box-shadow: none !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
} */
a.btn.btn-primary.backbutton {
    margin-bottom: 10px !important;
}
.form-group.new_2145 {
    margin-left: 14px !important;
}
button.btn.btn-primary.new_5214 {
    margin-top: 10px !important;
}