@media only screen and (max-width: 812px) and (min-aspect-ratio: 13/9) and (orientation: landscape){
  .mobile-rotate-wrap {
      display: block !important;
      overflow: hidden !important;
  }
  }

  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    .logo-right.home-logo-awaed-set img{
      margin-right: auto;
      padding-right: 30px;
    }
  }

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .logo-right {
    right: 0px;
}
.home-logo-awaed-set img {
  /* width: 80%; */
}
}

@media screen and (min-width: 864px) and (max-width: 1536px) {
  .home-logo-awaed-set img {
    /* width: 75%; */
    padding-bottom: 0px;
}
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .logo-right-down{
      margin-top: 14%;  
  }
  .side-icon{
    right: 0px;
  }
  .home-logo-awaed-set img {
    /* width: 78%; */
    padding-bottom: 0px;
}
.logo-right{
  right: 0px;
}

}



/*--------------------- responsive -----------------------------------------*/
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .home-logo-awaed-set img {
    /* width: 90%; */
    margin-right: auto;
}
.logo-right.home-logo-awaed-set img {
  /* width: 90%; */
  margin-right: auto;
}
.logo-right {
  right: 0px;
}
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .logo-right {
    right: -22px;
}
.home-logo-awaed-set img {
  /* width: 90%; */
  margin-right: auto;
  padding-right: 3px;
}
.logo-right.home-logo-awaed-set img {
  /* width: 65%; */
    margin-right: auto;
}
}


/* @media only screen and (max-width: 736px) and (orientation: landscape) and 
(min-aspect-ratio: 13/9){
  .mobile-rotate-wrap {
      display: block!important;
      overflow: hidden!important;
  }
} */

@media only screen and (min-device-width: 320px) 
and (max-device-width: 568px) and (orientation: portrait) {
  .mob-line-none{
    padding-bottom: 0rem!important;
  }
    .col-md-4.pronew-1 .pronew-2 a {
    opacity: 1 !important;
  }
  .col-md-4.pronew-1 .pronew-2 a {
    opacity: 1 !important;
}

.pro_wrap.pronew-2 a.projectlink {
  background-position: center 24%;
}

  .pro_wrap ul, .pro_wrap a.projectlink ul, .col-md-4.pronew-1 .pronew-2 a, .col-md-4.pronew-1 .pronew-2 a {
    opacity: 1 !important;
}
  .pronew-2:before a.projectlink, .col-md-4.pronew-1{
    opacity: 1 !important;
  }
  .pro_wrap ul, .pro_wrap a.projectlink ul, .pro_wrap ul{
    opacity: 1  !important;
}

  .col-md-4.pronew-1 .pronew-2 a {
    opacity: 1 !important;
}
  .pro_wrap a.projectlink{
    opacity: 1 !important;
}
  .col-md-4.pronew-1 .pronew-2 a, .pro_wrap a.projectlink{
    opacity: 1 !important;
}
  .pro_wrap a.projectlink{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.76);
    background-image: url('/images/pro_arrow.png');
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1 !important;
  }
  .pro_wrap ul li {
    padding-bottom: 6px;
}
  .pro_wrap ul{
    bottom: 0%;
  }
  .pro_wrap.pronew-2{
    padding-bottom: 6px;
  }
  .pro_wrap ul, .pro_wrap a.projectlink ul {
    opacity: 1;
}
  section.about-details .col-md-6.pl-5.pb-5.red-arrow-r.text-holding{
    padding-bottom: 0px !important;
    margin-bottom: 0px;
  }
  section.about-details .col-md-6.pl-5.pb-5.red-arrow-r.text-holding p:last-child{
    margin-bottom: 0rem;
  }
  .solution-content {
    padding-bottom: 0rem;
}
  .row.mt-5.copyrights{
    margin-top: 1.5rem!important;
  }
  .footer-bottom {
    padding: 30px 0;
}
  section.mobile-nav{
    display: block !important;
  }
  section.footer-bottom p br{
    display: block !important;
  }
  section.contact-section input[type="submit"]{
    width: 100%;  
  }
  .social-icons span {
    padding-right: 10px;
    padding-left: 10px;
}
section.contact-section {
  padding-bottom: 2rem;
}
  .card-columns, .contact-details, section.contact-section h3.mb-3{
    text-align: center;
}
  .solution-content br {
    display: block !important;
}
  section.client-logo-section .img-fluid {
    max-width: 100%;
    height: auto;
    width: 80%;
}
  .col-md-2.logo-imgs {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
  section.about-details, section.footer-nav, .footer-bottom{
    text-align: center;
}
.about-team-section ul.pro-detail{
  text-align: left;
}
.about-team-section .col-md-4.users-prop {
  margin-left: 30px;
  margin-bottom: 0px;
}
.join-the-KDS{
  margin-bottom: 0px;
}
section.about-details {
  padding-bottom: 15px;
}
.award-img {
  width: 60%;
  float: none;
}
section.about-details-awards {
  text-align: center;
}
.about-details-awards {
  padding-bottom: 0px;
}
.about-details-awards .col-md-6.pr-5.pb-5{
  padding-bottom: 0rem!important; 
}
section.about-details-awards .col-md-6.pr-5.pt-5.red-arrow-l{
  padding-right: 15px !important;
}
section.about-details-awards .col-md-6.pr-5.pt-5.red-arrow-l {
  padding-top: 0rem!important;
}
section.about-details-awards img.img-fluid.award-img.mb-5{
  margin-bottom: 0rem!important;
}
section.about-details-awards  .col-md-6.pr-5.pb-3.red-arrow-t{
  margin-bottom: 0rem!important;
}
}

/* iPhone 6, 7, & 8 in portrait & landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .mobile-menu, .mobile-show {
    display: block !important;
  }
  .side-icon {
    display: none !important;
  }
  ul.navigation-home-top li {
    display: block !important;
    width: 100%;
    text-align: center;
  }
  ul.navigation-home-top {
    margin-bottom: 0px;
  }
  ul.navigation-home-top li a {
    width: initial !important;
    font-size: 14px !important;
    padding: 17px 0px !important;
  }
  ul.navigation-home-top li a.active::before, ul.navigation-home-top li a.active::after {
    display: none;
  }
  section.about-section {
    display: none;
  }
  hr.top-sep {
    margin-bottom: 15px;
  }
  .col-md-6.pr-5.pb-5.red-arrow-t {
    padding-right: 15px !important;
    padding-bottom: 15px !important;
  }
  .col-md-6.pr-5.pb-5.red-arrow-t.text-holding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .col-md-6.pl-5.pb-5.red-arrow-r {
    padding-left: 15px !important;
    padding-bottom: 15px !important;
  }
  .col-md-6.pl-5.pb-5.red-arrow-r.text-holding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .red-arrow-t::after, .red-arrow-r::after, .red-arrow-b::after, .red-arrow-l::after, .red-arrow-t-c::before, .red-arrow-b-c::after {
    display: none;
  }
  .col-md-6.pr-5.pt-5.red-arrow-l.text-holding {
    padding-left: 30px;
    padding-right: 30px !important;
    padding-top: 0 !important;
  }
  .col-md-6.pl-5.pt-5.red-arrow-b {
    padding: 0 15px !important;
  }
  ul.social {
    text-align: left;
    margin-top: 16px;
  }
  
  ul.social li {
    margin: 0;
    margin-right: 10px;
  }
  section.footer-bottom .col-md-5 {
    margin-bottom: 29px;
  }
  .row.mt-5.copyrights {
    text-align: center;
  }
  .row.mt-5.copyrights .col-md-6.text-right {
    text-align: center !important;
    margin-top: 7px;
  }
  h3.red-arrow-t-c {
    margin: 0;
    margin-bottom: 30px;
  }
  .col-md-6.pr-5.pt-5.pb-5.solution-plus {
    padding: 0 15px !important;
  }
  .solution-wrap {
    margin-bottom: 15px;
  }
  .solution-plus:after {
    display: none;
  }
  section.solution-content .row.mb-5 {
    margin-bottom: 30px !important;
  }
  .col-md-6.pt-5.pl-5.pb-5 {
    padding: 0 15px !important;
  }
  .col-md-6.pr-5.pt-5.pb-5 {
    padding: 0 15px !important;
  }
  .contact-section .row.mb-5 {
    margin-bottom: 30px !important;
  }
  .contact-details {
    height: initial !important;
    padding: 20px !important;
  }
  .contact-details .mail-col {
    margin-top: 25px !important;
  }
  .contact-details .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contact-details .col-6:first-child {
    margin-bottom: 20px;
  }
  .contact-section .col-md-6.pr-5.pt-5.red-arrow-l {
    padding: 0 15px !important;
    margin-bottom: 40px;
  }
  .project-info ul.pro-detail {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  section.next-project-type {
    display: none;
  }
  .form {
    padding: 15px !important;
  }
  h3.red-arrow-b-c {
    margin-bottom: 40px;
  }
  .mobile-show {
    text-align: center;
  }
  .col-md-6.pl-5.pb-5 {
    padding: 0 15px !important;
    margin-bottom: 20px;
  }
  .col-md-4.users-prop {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  img.img-fluid.w-100.mb-70 {
    margin-top: 70px;
  }
  .pro_wrap img {
    width: 100%;
  }
  .pro_wrap {
    margin-bottom: 15px !important;
  }
  .site-logo img {
    height: initial !important;
  }
}

/* iPad mini in landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  ul.navigation-home-top-page li a {
    width: 130px !important;
  }
  .pro_wrap ul{
    bottom: -1%;  
  }
  .logo-right.home-logo-awaed-set{
    display: none;
  }
  .logo-right.home-logo-awaed-set{
    right: -56px;
    z-index: 9;
}
section.about-details {
  padding-bottom: 0px;
}
.logo-right.home-logo-awaed-set img{
  /* width: 40%;  */
  margin-right: 40px;
}
  .logo-left img {
    width: 110px;
    margin-top: 17px;
  }
  .side-icon-down{
    top: 42%;
  }
  .logo-right-down{
    margin-top: 18%;  
}
  ul.navigation-footer li a {
    margin-right: 21px !important;
  }
}

/* iPad mini in portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  section.mobile-nav{
    display: block !important;
  }
  ul.navigation-home-top li a{
    width: 120px;
  }
  section.project-lst .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
  .landscape-mode {
    display: block !important;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    overflow: hidden;
  }
  .logo-left{
    z-index: 9;
  }
  .landscape-mode p {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #000;
    font-weight: 500;
  }
  section.about-section {
    display: none;
}
.about-team-section .col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0rem!important;
}
.about-team-section .red-arrow-t::after{
  background-color: #ffffff; 
}
.about-details-awards {
  padding-bottom: 0px;
}
ul.navigation-footer li a{
  margin-right: 24px;
}
}



/* iPhone 4 and 4s Portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .mobile-menu, .mobile-show {
    display: block !important;
  }
  p.kds-main-text {
    /* color: #ee3330; */
    color: #ffffff;
    font-size: 10px!important;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 18px !important;
    padding-top: 15px;
    text-transform: uppercase;
    line-height: 20px;
    padding: 0px 20px;
}
  .kds-main-text-box {
    position: absolute;
    margin: auto;
    display: block;
    text-align: center;
    bottom: 10%;
    z-index: 111;
    left: auto;
    width: 100%;
}
  .col-md-4.pronew-1 .pronew-2 a {
    opacity: 1 !important;
}
  nav.navbar.navbar-expand-lg.navbar-light.bg-light.mobile-menu{
    display: flex !important;
  }
  .footer-nav ul.navigation-footer li a{
    margin-right: 14px;
    margin-left: 14px;
  }
  .side-icon {
    display: none !important;
  }
  ul.navigation-home-top li {
    display: block !important;
    width: 100%;
    text-align: center;
  }
  ul.navigation-home-top {
    margin-bottom: 0px;
  }
  ul.navigation-home-top li a {
    width: initial !important;
    font-size: 14px !important;
    padding: 17px 0px !important;
  }
  br{
    display: none !important;
  }
  ul.navigation-home-top li a.active::before, ul.navigation-home-top li a.active::after {
    display: none;
  }
  section.about-section {
    display: none;
  }
  hr.top-sep {
    margin-bottom: 15px;
  }
  .col-md-6.pr-5.pb-5.red-arrow-t {
    padding-right: 15px !important;
    padding-bottom: 15px !important;
  }
  .col-md-6.pr-5.pb-5.red-arrow-t.text-holding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .col-md-6.pl-5.pb-5.red-arrow-r {
    padding-left: 15px !important;
    padding-bottom: 15px !important;
  }
  .col-md-6.pl-5.pb-5.red-arrow-r.text-holding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .red-arrow-t::after, .red-arrow-r::after, .red-arrow-b::after, .red-arrow-l::after, .red-arrow-t-c::before, .red-arrow-b-c::after {
    display: none;
  }
  .col-md-6.pr-5.pt-5.red-arrow-l.text-holding {
    padding-left: 30px;
    padding-right: 30px !important;
    padding-top: 0 !important;
  }
  .col-md-6.pl-5.pt-5.red-arrow-b {
    padding: 0 15px !important;
  }
  ul.social {
    text-align: left;
    margin-top: 16px;
  }
  ul.social li {
    margin: 0;
    margin-right: 10px;
  }
  section.footer-bottom .col-md-5 {
    margin-bottom: 29px;
  }
  .row.mt-5.copyrights {
    text-align: center;
  }
  .row.mt-5.copyrights .col-md-6.text-right {
    text-align: center !important;
    margin-top: 7px;
  }
  h3.red-arrow-t-c {
    margin: 0;
    margin-bottom: 30px;
  }
  .col-md-6.pr-5.pt-5.pb-5.solution-plus {
    padding: 0 15px !important;
  }
  .solution-wrap {
    margin-bottom: 15px;
  }
  .solution-plus:after {
    display: none;
  }
  section.solution-content .row.mb-5 {
    margin-bottom: 30px !important;
  }
  .col-md-6.pt-5.pl-5.pb-5 {
    padding: 0 15px !important;
  }
  .col-md-6.pr-5.pt-5.pb-5 {
    padding: 0 15px !important;
  }
  .contact-section .row.mb-5 {
    margin-bottom: 30px !important;
  }
  .contact-details {
    height: initial !important;
    padding: 20px !important;
  }
  .contact-details .mail-col {
    margin-top: 25px !important;
  }
  .contact-details .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contact-details .col-6:first-child {
    margin-bottom: 20px;
  }
  .contact-section .col-md-6.pr-5.pt-5.red-arrow-l {
    padding: 0 15px !important;
    margin-bottom: 40px;
  }
  .project-info ul.pro-detail {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  section.next-project-type {
    display: none;
  }
  .form {
    padding: 15px !important;
  }
  h3.red-arrow-b-c {
    margin-bottom: 40px;
  }
  .mobile-show {
    text-align: center;
  }
  .col-md-6.pl-5.pb-5 {
    padding: 0 15px !important;
    margin-bottom: 20px;
  }
  .col-md-4.users-prop {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  img.img-fluid.w-100.mb-70 {
    margin-top: 70px;
  }
  .pro_wrap img {
    width: 100%;
  }
  .pro_wrap {
    margin-bottom: 15px !important;
  }
  .site-logo img {
    height: 60px;
  }
}